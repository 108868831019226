<template>
  <el-container class="login">
    <el-main>
      <el-card class="login_pannel">
        <div class="login_pannel_title">
          <el-image
            style="width: 100px; height: 100%"
            :src="require('@/assets/images/logo/logo.png')"
            :fit="contain"
          ></el-image>
        </div>
        <el-divider></el-divider>
        <el-form
          ref="formData"
          :model="formData"
          :rules="rules"
          label-width="70px"
          class="login_pannel_form"
        >
          <el-form-item label="Account" prop="account">
            <el-input
              v-model="formData.account"
              placeholder="Please enter the account"
              ><i slot="prefix" class="el-input__icon el-icon-user"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              type="password"
              v-model="formData.password"
              autocomplete="off"
              placeholder="Please enter the password"
              ><i slot="prefix" class="el-input__icon el-icon-key"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="Validcode" prop="validcode">
            <el-input
              class="vcode"
              style="width: 60%; display: inline-block"
              v-model="formData.validcode"
              autocomplete="off"
              placeholder="Please enter the validcode"
              @keyup.enter.native="submitForm('formData')"
              ><i slot="prefix" class="el-input__icon el-icon-arrow-right"></i
            ></el-input>
            <el-tooltip
              content="Can't see clearly? Click to change the one"
              placement="bottom"
              effect="light"
            >
              <el-image
                class="vcode"
                style="
                  width: 100px;
                  height: 40px;
                  margin-left: 20px;
                  float: right;
                "
                :src="imgBase64Code"
                :fit="contain"
                @click="getImg()"
              ></el-image>
            </el-tooltip>
          </el-form-item>
        </el-form>
        <div class="login_pannel_button">
          <el-button
            type="primary"
            @click="submitForm('formData')"
            :loading="isLoading"
            loading-text="Logging in..."
            >Login</el-button
          >
          <el-button @click="resetForm('formData')">Reset</el-button>
          <el-tooltip content="Click to get an account" placement="right">
            <el-link type="primary" :underline="false" class="register_link" @click="register"
              >Register</el-link
            >
          </el-tooltip>
        </div>
      </el-card>
    </el-main>
    <el-footer>
      <div class="copyright_info">
        <span>Powered by Tongren University</span>
      </div>
    </el-footer>
  </el-container>
</template>

<script >
export default {
  data() {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("The account cannot be empty"));
      } else {
        callback();
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("The password cannot be empty"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("The volidcode cannot be empty"));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      imgBase64Code: "",
      formData: {
        account: "",
        password: "",
        validcode: "",
        verify_value: "",
      },
      rules: {
        account: [{ validator: checkAccount, trigger: "blur" }],
        password: [{ validator: checkPass, trigger: "blur" }],
        validcode: [{ validator: checkCode, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getImg();
  },
  methods: {
    //获取验证码图片
    getImg() {
      this.$store.dispatch("user/getVolidCode").then((res) => {
        var { data } = res;
        this.imgBase64Code = data.captcha;
        //console.log(data.captcha)
        this.formData.verify_value = data.verify_value;
      });
    },
    submitForm(formData) {
      // 把加载状态改成true
      this.isLoading = true;
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.formData)
            .then((res) => {
              this.$message({
                showClose: true,
                message: "Login successful！",
                type: "success",
              });
              this.$router.push("/home");
              // 把加载状态改成false
              this.isLoading = false;
            })
            .catch((error) => {
              // 把加载状态改成false
              this.getImg();
              this.formData.validcode = "";
              this.isLoading = false;
            });
        } else {
          console.log("error submit!!");
          this.isLoading = false;
          return false;
        }
      });
    },
    resetForm(formData) {
      this.$refs[formData].resetFields();
    },
    register(){
      this.$router.push("/getAnAccount");
    }
  },
};
</script>

<style scoped>
.login {
  background-color: #304156;
  height: 100vh;
}
.login_pannel {
  margin: auto;
  margin-top: 20vh;
  width: 29rem;
  border-radius: 20px;
}

.login_pannel_title {
  margin: auto;
  margin-bottom: 5%;
  text-align: center;
}

.login_pannel_form {
  margin: 12% 0;
}

.login_pannel_button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  /*外层元素属性设置为center*/
  width: 100%;
}
.register_link {
  margin-left: 70px;
}
.copyright_info {
  margin-top: 1vh;
  text-align: center;
  color: #ccc;
}
</style>